.select_field {
    min-width: 135px;
}

.input_field {
    width: 258px !important;
}

.additional_input_field {
    width: 258px !important;
    margin-bottom: 30px;
}

.container {
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 136px;
    width: 930px;
    background-color: #f4faff;
    text-align: left;
}

.register_form {
    padding: 26px 46px;
}

.banner_img {
    width: 100%;
    margin-bottom: 25px;
}

.event_name h1 {
    font-size: 25px;
}

.label {
    font-weight: 700;
    margin-top: 5px;
    margin-right: 5px;
    display: inline-block;
}

.event_description {
    padding-top: 20px;
}

.prices {
    width: 400px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    padding: 5px;
}
.hr_custom {
    border: 0.5px solid #dcdcdc;
}

.logo_img {
    width: 100%;
    height: 100px;
}
