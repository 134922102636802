.event__input {
    width: 130%;
}

.event_types {
    padding-left: 15px;
}

.generated_element_wrapper {
    width: 45%;
    float: left;
    padding-left: 10px;
    padding-top: 15px;
}

.generated_element {
    width: 90% !important;
}

.clear {
    clear: both;
}

.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    left: 4px;
    font-size: 16px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.submit-inputs-button {
    padding: 20px 0 20px 0;
    text-align: center;
}

.accordion_event {
    margin: 30px 0 20px 0;
}

.img_banner {
    width: 100%;
    height: 250px;
}

.img_logo {
    width: 200px;
    height: 150px;
}

.logo_remove {
    width: 200px;
}

.logos_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    overflow-y: auto;
}

.item {
    display: flex;
    padding: 16px;
    position: relative;
    width: 220px;
    border-radius: 2px;
    flex-direction: column;
}

.inner-item {
    width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.SortableList {
    position: relative;
    z-index: 0;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;

    width: 400px;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #e0e0e0;
    list-style: none;
    padding: 0;
}

.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px,
        rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}

.ant-input-number {
    width: 100% !important;
}
