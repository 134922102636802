.board-section {
    max-width: 100%;
}

.ant-card {
    margin-top: 20px;
}

.title {
    width: calc(100% - 15px);
    margin-bottom: 0px;
}

.member {
    margin-bottom: 10px;
}

.deleteButton {
    padding: 0px;
    margin-left: 25px;
}

.headerContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.save {
    margin-top: 25px;
    margin-left: auto;
    display: block;
}

.ant-card {
    margin: 20px 0px;
}

.ant-card-head-title {
    padding: 24px 0px;
}
