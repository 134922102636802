.filter-category__container {
    display: flex;
    align-items: center;
}

.filter-category__select {
    margin-left: 75px;
}

.documents-list__ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
