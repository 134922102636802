.invitation-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.invitation-logged-user {
    background-color: #fff;
    width: 400px;
    box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 50px;
    padding-top: 75px;
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 90%;
}

.invitation-logged-user span[role="img"] {
    height: 100px;
    position: absolute;
    top: -50px;
    background-color: #CCC;
    color: #fff;
    width: 80px;
    font-size: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}