.articles-list > .ant-card-body {
    padding: 0px;
}

.article {
    border-top: 1px solid #e8e8e8;
    margin: 0px !important;
}

.article:hover {
    background-color: #f8f8f8;
}

.article .switch {
    margin-top: 10px;
}

.article p {
    display: inline;
    font-weight: bold;
    margin: 0px;
    display: block;
}

.article .field {
    padding: 5px 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.margin-around {
    margin: 10px;
}

.article .ant-row {
    margin-top: 25px;
}

.article .ant-row:first-of-type {
    margin-top: 0px;
}

.articles-list .ant-pagination {
    padding: 20px 0px !important;
}
