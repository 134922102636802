.delete-modal{
    display: flex;
    flex-direction: column;
}
.delete-item{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.delete-item p {
    margin-left: 10px;
}

.delete-title{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.delete-title span{
    font-size:20px; 
    color: orange;
}
.delete-title h3{
    margin-left:10px;
    margin-bottom: 0;
}

.wide-modal {
    width: 800px !important;
}

.add-member-icon {
    font-size: 18px;
    margin: 10px
}

.add-member-icon :hover {
    color: #68C93B;
}

.remove-member-icon :hover {
    color: #FD2526;
}