body {
    background-color: #f4faff;
    color: #000000;
}

.logo {
    text-align: center;
    margin: 0;
}

.login-logo {
    height: 32px;
    margin: 16px;
    text-align: center;
    margin-bottom: 24px;
}

.layout {
    min-height: 100vh;
    background-color: #f4faff;
}

.header {
    box-shadow: 0px 2px 4px -4px rgba(217, 217, 217, 0.25);
    border-bottom: 2px solid #fafafa;
    padding: 0;
}
.page-header {
    box-shadow: 0px 2px 4px -4px rgba(217, 217, 217, 0.25);
    padding: 0;
}

.content {
    width: 90%;
    margin: 41px auto;
    max-width: 95%;
}

.ant-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.rounded-profile-image {
    border-radius: 100%;
}

.blue-underline {
    position: relative;
}

.custom-card {
    text-align: left;
}

.custom-card.membership-type:hover {
    background-color: #f9f9f9;
}

.custom-card.active {
    background-color: #cdefff !important;
}

.blue-underline::before {
    background-color: #40a9ff;
    position: absolute;
    bottom: -5px;
    left: 0px;
    width: 35px;
    content: '';
    height: 3px;
}

.custom-card .ant-card-head {
    border-bottom: 0px;
}

.custom-card-header {
    font-size: 24px;
    font-weight: normal;
    color: black;
}

.custom-card .ant-table-tbody > tr:last-of-type > td {
    border-bottom: 0px;
}

.ant-table-thead > tr > th {
    color: black;
}

.space-right {
    margin-right: 15px;
}

.no-padding {
    padding: 0px;
}

.no-bold {
    font-weight: normal;
}

.input-profile {
    font-size: 16px;
    color: #222;
    margin-top: 10px;
}
.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: #f4faff;
}

.articles-list .ant-card-body {
    padding: 20px;
}

.article:hover {
    background-color: #f4faff;
}

.quick-access-card {
    font-size: 20px;
    font-weight: 700;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
    -moz-box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
    box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
}

.quick-access-card span[role='img'] {
    margin-right: 20px;
}

.dashboard-info-card {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
    -moz-box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
    box-shadow: 0px 0px 3px 1px rgba(212, 250, 255, 1);
}

.quick-access-card:hover {
    background: #f7f7f7;
}

.profile-address__label {
    display: block;
}

.profile-phone__label {
    display: block;
}

.select-membership {
    max-width: 1000px;
    text-align: center;
}

.align-left {
    text-align: left;
}

.documents-action {
    display: block;
}

.documents-action button {
    padding-left: 0px;
    margin-right: 0px;
}

.sponsorship-item {
    padding: 10px;
}

.sponsorship-item :hover {
    cursor: pointer;
}

.choosen-sponsorship {
    background-color: #40a9ff;
    padding: 10px;
    color: #FFFFFF;
    cursor: pointer;
}

.setup-text {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    display: block;
    max-width: 50%;
    margin: 0px auto;
    color: #000;
    margin-top: 100px;
}

.setup-header {
    padding: 20px;
    background: #FFFFFF;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.small-grey-label {
    font-size: 10px;
    color: grey;
}

.blue-divider {
    background-color: #1673DC;
    padding: 2px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.big-bold-text {
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    letter-spacing: 1px;
}

.ant-steps-item-finish .ant-steps-item-title {
    color: #B4D5F4 !important;
}

.ant-steps-item-active .ant-steps-item-title {
    color: #328FE3 !important;
}

.error-message {
    background-color: #FDEDED;
    color: #000;
    font-size: 14px;
    padding: 15px;
    border-left: 3px solid #E80003;
    text-align: left;
}

.success-message {
    background-color: #dcffdc;
    color: #000;
    font-size: 14px;
    padding: 15px;
    border-left: 3px solid #00E803;
    text-align: left;
}

.error-message button {
    padding: 0px;
    height: auto;
}

.terms-link {
    color: inherit;
    text-decoration: underline;
}

.terms-link:hover {
    color: #000;
    text-decoration: underline;
}

.page-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 30px;
}

.membership-link {
    color: inherit !important;
    text-decoration: none;
}

.membership-link:hover {
    text-decoration: underline;
}

.add-event-element-button {
    background-color: #FAFAFA;
    color: rgba(0, 0, 0, 0.65);
    border: 1px dashed #ddd;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    display: block;
    transition: all .3s linear;
    width: 100%;
    outline: none;
}

.add-event-element-button:hover {
    border-color: #1673DC;
}

.add-event-element-button.light {
    background-color: #fff;
    display: inline-block;
    padding: 5px;
}

.additional-field-button {
    margin-left: 10px;
    cursor: pointer;
}

.additional-field {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
}

.smooth-dnd-container {
    min-height: unset;
}

.dashboard-info {
    display: block;
    width: 100%;
    margin: 5px 0px;
    padding: 15px;
    text-align: left;
    word-break: break-all;
    font-size: 14px;
}

a.profile-link {
    color: #1890FF;
    text-decoration: underline;
}

a.profile-link:hover {
    color: #0560CC;
}

.category-description {
    margin-top: 20px;
}

.category-description p {
    margin-bottom: 0px;
    color: #000;
}

.autopay {
    margin-top: 25px;
    color: #000000;
}

.autopay p {
    color: #000;
    margin-top: 10px;
}

.user-details.element p {
    margin-bottom: 2px;
    margin-top: 20px;
    color: #000;
}

.user-details.element:first-of-type p {
    margin-top: 0px;
}

.user-details.element .list {
    margin-top: 5px;
}

.user-details.element .list:first-of-type {
    margin-top: 0px;
}

.user-details.element span {
    color: #888;
}

.ant-table-tbody {
    color: #000;
}

.ant-menu-submenu-title > a {
    color: rgba(0, 0, 0, 0.65);
}

.ant-menu-submenu-title :hover {
    color: #1890FF;
}

.profile-logo {
    cursor: pointer;
}

.profile-logo:hover {
    opacity: 0.8;
}

.profile-logo-icon {
    padding: 12px;
    font-size: 20px;
    position: absolute;
    right: 5%;
    bottom: 5%;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
}

.profile-logo-icon:hover {
    background-color: rgba(0, 0, 0, .4);
}

.mobile-user-profile {
    display: none;
}

.mobile-responsive-menu {
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 99999;
}

.table-mobile {
    display: none;
}

.table-mobile .table-row {
    display: block;
    padding: 15px 0px;
    border-bottom: 1px solid #eee;
}

.table-mobile .table-row:last-of-type {
    border-bottom: unset;
}

.table-mobile .table-column .table-data {
    margin-top: 5px;
    margin-bottom: 15px;
}

.table-mobile .table-column .table-data a, .table-mobile .table-column .table-data button {
    display: block;
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-top: 15px !important;
}

.table-mobile .table-column .table-data button.link-btn a {
    margin-top: 0px !important;
    font-size: 15px;
}

.table-mobile .table-column .table-data br {
    display: none;
}

.table-desktop {
    display: block;
}

.profile-data {
    display: flex;
    flex-direction: row;
}

.mobile-filters {
    display: none;
}

.ant-page-header-heading-extra {
    display: flex !important;
}

.profile-reset-pwd-input {
    width: 100% !important;
}

.password-reset-button {
    margin-top: 4px;
}

.setup-name {
    padding: 0px;
}

.display-mobile {
    display: none;
}

@media screen and (max-width: 992px) {
    .login-page .login-logo {
        display: none;
    }

    .user-profile {
        display: none;
    }

    .ant-page-header-heading-title {
        font-size: 16px;
    }

    .left-sider {
        display: none;
    }

    .mobile-user-profile {
        display: block;
    }

    .ant-dropdown-menu {
        padding: 15px;
    }

    .ant-dropdown-menu .user-name {
        padding: 5px;
        display: inline-block;
    }

    .ant-card-body span {
        font-size: 16px;
    }
    
    .table-mobile {
        display: block;
    }

    .table-desktop {
        display: none;
    }

    .profile-data {
        flex-direction: column;
    }

    .profile-data > div {
        margin-top: 25px;
    }

    .profile-data > div:first-of-type {
        margin-top: 0px;
    }

    .profile-data-name {
        margin-top: 25px;
    }

    .profile__container {
        padding-top: 0px !important;
    }

    .profile-logo {
        max-height: 250px;
        display: block;
        margin: 0px auto;
        width: auto !important;
        max-width: 100%;
    }

    .profile-data-user-name {
        display: flex;
        flex-flow: column nowrap;
    }

    .profile-data-user-name > div {
        margin-top: 25px;
    }

    .profile-data-user-name > div:first-of-type {
        margin-top: 0px;
    }

    .ant-legacy-form-inline .ant-legacy-form-item {
        width: 100%;
    }

    .profile-phone {
        flex-flow: column !important;
    }

    .profile-phone .custom-card {
        margin: 10px 0px !important;
    }

    .ant-select-multiple .ant-select-selector {
        width: 100%;
    }

    .professional-info__row {
        flex-flow: column !important;
    }

    .professional-info__input {
        min-width: unset !important;
    }

    .ant-card-head-wrapper {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }

    .ant-card-head-wrapper .ant-card-extra {
        margin-left: 0px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
    }

    .button-no-padding-no-border {
        margin-left: 0px !important;
        padding-left: 0px !important;
        border-left: none !important;
    }

    .mobile-filters {
        display: block;
    }
    
    .mobile-filters-content {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
        z-index: 99;
    }

    .mobile-filters-content .mobile-header {
        box-shadow: 0px 0px 5px #888;
        padding: 15px;
    }

    .mobile-filters-content .filter {
        max-width: unset;
        margin: 0px;
    }

    .mobile-filters-content .filter-content {
        padding: 15px;
    }

    .mobile-filters-content .buttons {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 10px;
        padding: 15px;
        box-shadow: 0px 0px 5px #bbb;
        border-radius: 10px 10px 0px 0px;
    }

    .mobile-filters-content .filter-content .buttons button:first-of-type {
        background-color: #0560CC !important;
        color: #fff;
        width: 100%;
        margin-bottom: 15px;
    }

    .mobile-filters-content .filter-content .buttons button:nth-of-type(2) {
        color: #fff;
        color: #000;
        width: 100%;
    }

    .filter-card-header {
        display: none;
    }

    .hide-mobile {
        display: none !important;
    }

    .display-mobile {
        display: block;
    }

    .ant-form-item {
        margin-bottom: 0px;
    }

    .ant-legacy-form-item {
        margin-bottom: 0px !important;
    }

    .password-reset-button {
        margin-top: 0px;
    }

    .custom-card-header {
        font-size: 18px;
    }

    .ant-card-extra .ant-btn-link {
        display: block;
        padding: 10px 0px;
        height: auto;
    }

    .ant-card-body button {
        display: block;
        width: 100%;
        text-align: left;
    }
    
    .ant-tag {
        white-space: normal;
        word-break: keep-all;
    }

    .login-page .login-content .wrapped-content.message {
        padding: 25px;
    }

    .individual-tabs {
        padding: 30px 10px !important;
        display: block !important;
    }

    .login-page .login-content {
        padding: 25px;
    }

    .setup-text {
        max-width: 100%;
        margin-top: 25px;
    }

    .setup-name {
        padding: 8px;
    }

    .thank-you-body {
        padding: 25px !important;
    }

    .thank-you-body button {
        text-align: center !important;
    }
}


.large-alert .ant-alert-description {
    font-size: 16px;
    color: #000;
}

.large-alert .ant-alert-message {
    font-size: 18px;
    color: #000;
}

.hide { display: none; }

.add-article-modal .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error:last-child .ant-form-item-explain-error,
.edit-article-modal .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error:last-child .ant-form-item-explain-error {
    margin-top: 3rem;
}