.setup-header {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 5px 5px rgba(79, 79, 79, 0.07);
}

.individual-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.setup-card, .company-request {
    box-shadow: 0px 10px 30px rgba(79, 79, 79, 0.07);
    border-radius: 5px;
    width: 700px;
    max-width: 90%;
    margin: 0px auto;
}

.company-request {
    box-shadow: unset;
}

.setup-card .ant-upload.ant-upload-select {
    display: block;
}

.setup-card .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.setup-card .ant-upload.ant-upload-select-picture-card {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 215px;
    height: 290px;
    min-height: 200px;
}

.setup-card .ant-upload.ant-upload-select-picture-card img {
    width: 100%;
    height: 100%;
}

.setup-card h1 {
    font-size: 25px;
    display: block;
    text-align: center;
    padding: 25px;
    margin-bottom: 25px;
}

.avatar-uploader {
    width: 100%;
}

.setup-card .checkout {
    display: flex;
}

.payment-card-text {
    color: #000;
    margin-top: 25px;
}

.payment-card-note {
    color: #737373;
    margin: 25px 0px;
}

.payment-card-note:first-of-type {
    margin-top: 0px;
}

.setup-card .checkout {
    display: flex;
    align-items: center;
}

.setup-card .checkout .ant-btn-primary {
    margin-left: 25px;
}

.thank-you-card {
    box-shadow: 0px 10px 30px rgba(79, 79, 79, 0.07);
}

.thank-you-body {
    text-align: center;
    padding: 50px;
}

.thank-you-body .ant-avatar {
    background-color: #F4FAFF;
}

.thank-you-body span {
    width: 75px;
    height: 75px;
}

.company-request {
    text-align: center;
    margin-top: 50px;
}

.company-request p {
    margin-top: 25px;
}

.company-request-select {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
}

.thank-you-body h1 {
    margin-top: 10px;
}

.thank-you-divider {
    margin-bottom: 25px;
    margin-top: -10px;
}

.thank-you-text {
    color: #000;
}