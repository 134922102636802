.filters {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
}

.filters .ant-btn-background-ghost.ant-btn-primary {
    color: #40a9ff;
    border-color: #40a9ff;
    margin-left: 15px;
}

.clear-filters {
    padding: 0px;
}

.filters-header {
    font-size: 24px;
    font-weight: normal;
    color: #000;
    position: relative;
    display: block;
}

.filter-card-header .ant-card-head {
    border: none;
}

.filter {
    width: 100%;
    max-width: 200px;
    margin: 0px 15px;
}

.filter .ant-select {
    width: 100%;
}

.filter:first-of-type {
    margin-left: 0px;
}

.filter-title {
    font-weight: bold;
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
}
