body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

table {
    background: white;
}

h4.inline-header {
    display: inline;
}

.u--secondary-background {
    background-color: white;
    border-radius: 4px;
}

.u--row-spacer {
    margin-bottom: 30px;
}

.button__link {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #1890ff;
    outline: none;
}

.button__link:hover {
    color: #40a9ff;
}

.u--push-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.u--secondary-text {
    font-size: 14px;
    line-height: 22px;
}

.u--highlighted-text {
    color: #000000d9;
}

.u--spacer-top-1x {
    margin-top: 30px;
}

.u--spacer-bottom-1x {
    margin-bottom: 30px;
}

.u--spacer-right-1x {
    margin-right: 30px;
}

.u--spacer-right-2x {
    margin-right: 60px;
}

.u--spacer-left-1x {
    margin-left: 30px;
}

.main-heading {
    font-size: 30px;
    line-height: 38px;
    color: black;
}

.secondary-heading {
    position: relative;
}

.secondary-heading::after {
    content: '';
    position: absolute;
    width: 35px;
    height: 2px;
    background: #40a9ff;
    bottom: -10px;
    left: 0;
}

.u--text-center {
    text-align: center;
}
