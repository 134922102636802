.profile {
    /* background: white; */
    text-align: center;
}

/* ovveride antdesign */
.ant-tabs-bar {
    border-bottom: none;
}

.profile__pane {
    background: #f4faff;
    font-size: 16px;
    line-height: 24px;
}

.profile__container {
    margin: 0 auto;
    padding-top: 48px;
    background-color: #f4faff;
    text-align: left;
}

.profile__img-upload-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* 215px */
    width: 199px;
    /* 290px */
    height: 274px;
    margin: 0;
}

.profile-reset-pwd-input {
    width: 170px;
    margin-right: 35px;
}

.profile-member-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.profile-member-status__select {
    min-width: 135px;
    margin-left: 20px;
}

.profile-opinions {
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-opinions__box {
    padding: 30px;
    border-left: 11px solid #e6f7ff;
}

.profile-opinions__box:hover {
    border-left: 11px solid #bdcfe9;
}

.profile-opinions__input {
    width: 100%;
}

.profile-address {
    padding: 26px 46px;
}

.profile-address__heading {
    margin-bottom: 24px;
}

.profile-address__label {
    font-size: 14px;
    color: #000000;
}

.profile-address__input {
    width: 258px;
}

.profile-phone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-phone .custom-card {
    margin: 0px 10px;
    width: 100%;
}

.profile-phone .custom-card:first-of-type {
    margin-left: 0px;
}

.profile-phone .custom-card:last-of-type {
    margin-right: 0px;
}

.profile-phone__container {
    width: 290px;
    padding: 26px 46px;
}

.profile-phone__inputs_group {
    display: flex;
    flex-direction: row;
}

.profile-phone__label {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}

.profile-phone__group_input {
    width: 90px;
}

.profile-full-width-button {
    text-align: center;
}

.profile-comments {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.profile-comments__add-comment {
    position: relative;
    width: 508px;
    padding: 39px 46px;
}

.profile-comments__date {
    position: absolute;
    right: 45px;
    top: 46px;
    font-size: 14px;
    line-height: 22px;
    color: #1890ff;
}

.profile-comments__save {
    border: 0;
    box-shadow: 0 0 0;
}

.profile-comments__list {
    width: 388px;
}

.profile-comment {
    padding: 35px 24px;
    border-bottom: 1px solid #d9d9d9;
}

.profile-comment__date {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 22px;
    color: #000;
}

.profile-comment__buttons {
    display: flex;
    justify-content: space-between;
}

.profile-comments__load-more {
    text-align: center;
    padding: 35px 24px;
}

/* professional informatio */
.professional-info__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.professional-info__container {
    display: flex;
    flex-direction: column;
    color: #000000;
}

.professional-info__input {
    min-width: 400px;
}
