.membership-details{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}
.membership-details div{
  width: 100%;
}

.membership-actions {
  height: 100%;
  display: flex;
  align-items: flex-end;
}