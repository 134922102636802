.login-page {
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-flow: row nowrap;
}

.login-page .login-logo {
    background: linear-gradient(146.69deg, rgba(0, 114, 220, 0.17) 0%, rgba(194, 199, 248, 0.06) 100%);
    display: flex;
    justify-content: center;
    margin: 0px;
    height: unset;
}

.login-page .login-logo img {
    width: 300px;
}

.login-page .login-content {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
}

.submit-button {
    background-color: #0072DC;
    border-color: #0072DC;
    padding: 5px;
    height: 40px;
}

.submit-button:disabled {
    border-color: #0072dc54;
    background-color: rgba(0, 114, 220, 0.33);
    color: #fff;
}

.login-page .login-content .wrapped-content {
    max-width: 800px;
    width: 100%;
    text-align: center;
}

.login-page .login-content .wrapped-content.message {
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(79, 79, 79, 0.07);
    border-radius: 5px;
    padding: 50px;
}

.login-page .login-content .wrapped-content input {
    border-radius: 5px;
}

.login-page .login-content .ant-form-item-explain {
    text-align: left;
}

.login-page .login-content .additional-info {
    color: #737373;
    font-size: 13px;
    margin-top: 100px;
    line-height: 19px;
}

.select-membership-type {
    text-align: center;
}

.select-membership-type h1 { 
    margin-bottom: 25px;
}

.membership-type {
    padding: 25px;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 10px 30px rgba(79, 79, 79, 0.07);
    border-radius: 5px;
    height: 100%;
    border: 2px solid #fff;
    cursor: pointer;
}

.membership-type.selected {
    border-color: #0072DC;
}

.membership-type img {
    display: block;
    margin: 0px auto;
    margin-top: 25px;
    width: 60px;
}

.membership-type h2 {
    color: #0072DC;
    font-weight: normal;
    font-size: 24px;
    margin: 25px 0px;
}

.membership-type img {
    font-size: 16px;
}

.login-message-area {
    color: #000;
}

.login-message-area img {
    width: 60px;
}

.login-message-area h2 {
    margin-top: 45px;
    font-size: 30px;
}

.login-message-area h3 {
    margin-top: 45px;
    font-size: 18px;
}

.login-message-area ul {
    text-align: left;
    padding: 0px 15px;
}

.login-message-area p:nth-of-type(2) {
    text-align: left;
}

.login-message-area button {
    margin-top: 25px;
    font-weight: bold;
}

.email-confirmation {
    color: #000;
}

.email-confirmation img {
    width: 80px;
}

.email-confirmation h2 {
    margin-top: 25px;
    font-size: 25px;
}

.email-confirmation h2:first-of-type {
    margin: 50px 0px;
}

.email-confirmation button {
    max-width: 200px;
}